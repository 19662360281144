import { css } from 'styled-components';

// Types
import type { ButtonProps } from '.';
export const buttonPrimaryCss = css<ButtonProps>`
  ${({
  theme
}) => css`
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);

    &[data-disabled='true'] {
      background-color: ${theme.color.primary200};
    }

    &[data-disabled='false'] {
      &:hover {
        border-top: none;
        border-bottom: none;
      }
    }

    &[data-active='true'] {
      background-color: ${theme.color.primary900};
    }

    &[data-loading='true'] {
      background-color: ${theme.color.primary};
    }
  `};
`;